<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>Расчет расходов на оплату труда политических государственных служащих, депутатов, судей<budget-forms-list :curFormSelect="'/form01-123'"/></span></div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeParams"></budget-header>
            </div>
            <div class="right-content">
                <div class="actions-tab">
                    <budget-attach-file/>
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-download"></i> Скачать
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="download02_111">Форма 02-111</b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                    <b-button variant="success" @click="prepareForSave">Сохранить</b-button>
                </div>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header
                no-border-collapse
            >
                <thead>

                </thead>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(more)="data">
                    <b-button style="color:green" @click="addItem">+</b-button>
                </template>

                <template #cell(action)="data">
                    <div></div>
                </template>
                <template #cell(position)="data">
                    <b-form-select size="sm"
                                   class="w-100"
                                   v-model="data.item.position"
                                   :options="listPos"
                                   @change="v => data.item.position = v"
                    >
                    </b-form-select>
                </template>
                <template #cell(experience)="data">
                    <b-form-select size="sm"
                                   class="w-100"
                                   v-model="data.item.experience"
                                   :options="listExps"
                                   @change="v => data.item.experience = v"
                    >
                    </b-form-select>
                </template>
                <template #cell(staff_units)="data">
                    <b-form-input class="text-right"
                                  :value="data.item.staff_units"
                                  @change="v => data.item.staff_units = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="noAbc">
                    </b-form-input>
                </template>
                <template #cell(factor)="data">
                    <b-form-input class="text-right"
                                  :value="data.item.factor"
                                  @change="v => data.item.factor = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="noAbc">
                    </b-form-input>
                </template>
                <template #cell(multi_factor)="data">
                    <b-form-input class="text-right"
                                  :value="data.item.multi_factor"
                                  @change="v => data.item.multi_factor = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="noAbc">
                    </b-form-input>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>

                <template #cell(more)="data">
                    <b-button @click="deleteItem(data.item, data.index)">
                        <i class="icon icon-close"></i>
                    </b-button>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="3">ИТОГО</td>
                    <td class="text-right">{{ $n(units) }}</td>
                    <td class="text-right" colspan="3">{{ $n(smonth) }}</td>
                    <td class="text-right">{{ $n(parseFloat(smonth.toFixed(3))) }}</td>
                    <td class="text-right">{{ $n(syear) }}</td>
                    <td></td>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';

import BudgetHeader from '@/modules/budget-request/budget-header.vue';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import {Ax} from "@/utils";
import BudgetAttachFile from "./components/budget-attach-file";

export default {
    name: 'Form02_111',
    components: { BudgetHeader, BudgetFormsList, BudgetAttachFile },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'position',
                    label: 'Наименования должностей'
                },
                {
                    key: 'experience',
                    label: 'Стаж гос. службы в годах'
                },
                {
                    key: 'staff_units',
                    label: 'Кол-во штатных единиц'
                },
                {
                    key: 'factor',
                    label: 'Коэффициенты'
                },
                {
                    key: 'multi_factor',
                    label: 'Повышающий коэф.'
                },
                {
                    key: 'salary_month',
                    label: 'Сумма должн. окладов в месяц, тыс./тг'
                },
                {
                    key: 'salary_basic',
                    label: 'Итого основной заработной платы в месяц, тыс./тг',
                    formatter: (value, key, item) => {
                        return item.salary_month;
                    }
                },
                {
                    key: 'salary_year',
                    label: 'Итого основной заработной платы в год, тыс./тг'
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],

            curHeader: {},

            form: {
                code: '02-111',
                name_kk: 'Саяси мемлекеттiк қызметшiлердiң, депутаттардың, судьялардың еңбекақыларына арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов на оплату труда политических государственных служащих, депутатов, судей'
            },

            filter: {
                position: null,
                experience: null,
                search: null,
                on: ['filter', 'position', 'experience']
            },

            listJob: [],
            listPos: [],
            listExps: [],

            budgetForm: [],

            ind_bs: 17697,

            open: true,
            editing: false
        };
    },
    async mounted() {
        await this.loadJob();

        await this.loadExps();

        await this.loadDatas();
    },
    methods: {
        addItem() {
            const that = this;
            const item = {
                id: 0,
                position: null,
                experience: null,
                staff_units: 0,
                factor: 0,
                multi_factor: 0
            };

            Object.defineProperty(item, 'salary_month', {
                get: function() {
                    return parseFloat(((item.staff_units * that.ind_bs * item.factor * item.multi_factor) / 1000).toFixed(2));
                }
            })
            Object.defineProperty(item, 'salary_year', {
                get: function() {
                    return parseInt((item.salary_month * 12).toFixed(0));
                }
            })

            that.budgetForm.push(item);
        },

        async changeParams(data) {
            const that = this;
            that.curHeader = data;

            await that.loadDatas();
        }, // фиксирует изменение параметров шапки

        async delete(item) {
            try {
                const response = await fetch('/api-py/delete-form02-111/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status == 200) && (result.result == 'success')) {
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения данных');
            }
        }, // удаление данных

        deleteItem(row, index) {
            const that = this;

            that.$bvModal.msgBoxConfirm(
                'Подтвердите удаление данной записи...',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        that.budgetForm.splice(index, 1);
                        if (row.id > 0) {
                            that.delete(row.id);
                        }
                    }
                })
                .catch(error => {
                    that.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.value.code == code) {
                    return item.value;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            that.budgetForm.splice(0);

            let values = [];
            try {
                const response = await fetch('/api-py/budget_request_form_02_111/');
                values = await response.json();

                if (values.length == 0) {
                    return;
                }
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
                return;
            }

            for (const val of values) {
                if ((val.form == that.form.code) &&
                    (val.year == that.curHeader.year) &&
                    (val.data_type ==that.curHeader.dataType.code) &&
                    (val.gr == that.curHeader.gr.gr) &&
                    (val.abp == that.curHeader.abp.abp) &&
                    (val.prg == that.curHeader.prg.prg) &&
                    (val.ppr == that.curHeader.pgr.ppr) &&
                    (((that.curHeader.gu == null) && (val.ga == null)) || (val.ga == that.curHeader.gu.id)) &&
                    (val.spf == that.curHeader.spf.spf)) {

                    const item = {
                        id: val.id,
                        position: that.getItem(val.position, that.listPos),
                        experience: that.getItem(val.experience, that.listExps),
                        staff_units: val.staff_units,
                        factor: val.factor,
                        multi_factor: val.multi_factor
                    };

                    Object.defineProperty(item, 'salary_month', {
                        get: function() {
                            return parseFloat(((item.staff_units * that.ind_bs * item.factor * item.multi_factor) / 1000).toFixed(2));
                        }
                    })
                    Object.defineProperty(item, 'salary_year', {
                        get: function() {
                            return parseInt((item.salary_month * 12).toFixed(0));
                        }
                    })
                    that.budgetForm.push(item);
                }
            }
        },

        async loadExps() {
            let items = []
            try {
                const response = await fetch('/api-py/dict-work-exper/');
                items = await response.json();

                for (const item of items) {
                    this.listExps.push({
                        text: item.code + ' - ' + item.name_ru,
                        value: item
                    })
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadExps()', error.toString());
            }
        },

        async loadJob() {
            let items = []
            try {
                const response = await fetch('/api-py/dict-government-officials/');
                items = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadJob()', error.toString());
            }

            for (const item of items) {
                if (item.name_ru.startsWith('Категория')) {
                    this.listJob.push({
                        text: item.code + ' - ' + item.name_ru,
                        value: item
                    })
                } else {
                    this.listPos.push({
                        text: item.code + ' - ' + item.name_ru,
                        value: item
                    })
                }
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        }, // сообщение

        noAbc: function (evt) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if (!regex.test(key)) {
                evt.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const that = this;

            const values = [];
            for (const row of that.budgetForm) {
                if ((row.position !== null) &&
                    (row.experience !== null)) {
                    const item = {
                        id: row.id,
                        form: that.form.code,
                        year: that.curHeader.year,
                        data_type: that.curHeader.dataType.code,
                        gr: that.curHeader.gr.gr,
                        abp: that.curHeader.abp.abp,
                        prg: that.curHeader.prg.prg,
                        ppr: that.curHeader.pgr.ppr,
                        gu: (that.curHeader.gu == null ? null : that.curHeader.gu.id),
                        spf: that.curHeader.spf.spf,
                        position: row.position.code,
                        experience: row.experience.code,
                        staff_units: row.staff_units,
                        factor: row.factor,
                        multi_factor: row.multi_factor
                    }
                    values.push(item);
                }
            }
            if (values.length > 0) {
                that.save(values);
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') { return; }
            if (!item.visible) { return 'is-hidden'; }
        }, // задает класс 'is-hidden' заданной строке

        async save(values) {
            try {
                const response = await fetch('/api-py/budget_request_form_02_111/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status == 200) && (result.result == 'success')) {
                    this.loadDatas();
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения данных');
            }
        }, // сохранение данных

        userLogin() {
            return store.state.user.login;
        }, // имя пользователя
        download02_111() {
            const that = this;
            Ax(
                {
                    url: '/api-py/budg_02_111/' + that.form.code + '/' + that.curHeader.year + '/' + this.ind_bs + '/'
                        + 1 + '/' + 1, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма 02-111.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
    },
    computed: {
        units() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseInt(row.staff_units);
            }
            return sum;
        },
        smonth() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.salary_month);
            }
            return parseFloat(sum.toFixed(2));
        },
        syear() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.salary_year);
            }
            return sum;
        }
    }
};
</script>
